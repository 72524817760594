<template>
	<div
		class="flex-column full-height "
	>
		<div
			v-if="user.account_phone_number"
			class="pa-20 flex-column full-height overflow-y-auto "
		>

			<h6>휴대폰 번호</h6>

			<div class="bg-white shadow">

				<div class="flex-row justify-space-between">

					<div class="flex-1 pa-10  ">{{ user.account_phone_number }}</div>

					<div
						class="under-line-ddd"
					>
						<button
							v-if="!item.is_on_sms_confirm"
							@click="postSmsAuth"
							class="btn btn-primary"
							:disabled="is_sms_auth"
						>인증요청</button>
						<button
							v-else
							@click="resetSmsAuth"
							class="btn btn-primary"
						>다시하기</button>
					</div>
				</div>
			</div>

			<div
				class="mt-10"
				v-if="item.is_on_sms_confirm"
			>
				<h6>SMS 인증</h6>
				<div
					v-if="!item.is_sms_confirm"
					class="bg-white shadow"
				>
					<div class="flex-2">
						<input
							v-model="item.sms_code"
							type="number" placeholder="인증코드를 입력하세요"
							class="input-box"
							:rules="[$rules.max(item, 'sms_code', 6)]"
						/>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="pa-10 full-height justify-center items-center size-px-24 text-center"
		>
			등록된 연락처가 없습니다. <br/><br/> 관리자에게 문의하세요
		</div>
		<div
			class="bottom"
		>
			<button
				class="btn btn-identify"
				@click="postSmsConfirm"
				:disabled="is_on_sms_confirm_button"
			>SMS 인증 확인</button>
		</div>
		<PopupConfirm
			v-if="item.is_on_sms_confirm && item.is_sms_confirm"
			:not_cancel="true"
			@click="toMyPage"
		>
			<template
				v-slot:title
			>SMS 인증 완료</template>
			<template
				v-slot:main-txt
			>SMS 인증이 완료되었습니다.</template>
		</PopupConfirm>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";
export default{
	name: 'SmsConfirmRequest'
	, components: {PopupConfirm}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'SMS 인증 등록'
				,top: false
				,title: true
				,bottom: false
				,class: 'blue'
			}
			,item: {
				sms_confirm: false
				, sms_code: ''
				, is_use_sms_auth: true
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, type: 'auth'
			}
		}
	}
	, computed: {

		is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.user.account_phone_number){
				t = false
			}
			return t
		}
		, is_on_sms_confirm_button: function(){
			let t = true
			if(this.item.sms_code.length == 6 && this.item.is_on_sms_confirm){
				t = false
			}
			return t
		}
	}
	, methods: {
		resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				let result = await this.$Axios({
					method: 'post'
					, url: '/member/postSmsAuth'
					, data: {
						type: this.item.type
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				let result = await this.$Axios({
					method: 'post'
					,url: '/member/postSmsConfirm'
					,data: {
						sms_code: this.item.sms_code
						, type: this.item.type
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
					this.$bus.$emit('updateUser', {
						is_sms_confirm: 1
					})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, toMyPage: function(){
			this.$bus.$emit('to', { name: 'MyPage', type: 'href'})
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
	}
}

</script>

<style>
.content input[type=text] { width: 100%; border-bottom: 1px solid #ddd; padding: 10px 0;}
</style>